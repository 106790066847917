import React from "react"
import { Link } from "gatsby"
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import { isDesktop } from 'react-device-detect';

import { useMagnetic } from "./hooks/useMagnetic"

export const WorksLink = (props) => {
	const {x, y, textX, textY, mouseEnter, mouseMove, mouseLeave} = useMagnetic()

	return (
		<Controller>
			<Scene
				duration="200%"
				triggerHook="onEnter"
			>
				<Timeline
					wrapper={
						<Link 
							to={`/works/${props.id}`} 
							className="works__containerSecBlockBoxInner magnetic"
							onMouseEnter={mouseEnter}
							onMouseMove={mouseMove}
							onMouseLeave={mouseLeave}
						/>
					}
				>
					<figure className="works__containerSecBlockBoxInnerFig">
						<Tween
							position="0"
							from={
								isDesktop?
								{
									yPercent: -30,
								}
								:
								{
									yPercent: -15,
								}
							}
							to={{
									yPercent: 0,
							}}
						>
							<img src={props.url} alt={props.title} />
						</Tween>
					</figure>
					<div className="works__containerSecBlockBoxInnerTxt">
						<div 
							className="works__containerSecBlockBoxInnerTxtIn"
							style={
								isDesktop?
								{transform: `translate(${x}px, ${y}px)`}
								:
								null
							}
						>
							<h2>{props.title}</h2>
							<span>{props.launch.replace(/\.[0-9][0-9]/, "")}</span>
						</div>
					</div>
					{isDesktop &&
						<span 
							className="cmn__mouse"
							style={{transform: `translate(${textX}px, ${textY}px)`}}
						>
							<span>
								<span>View</span>
							</span>
						</span>
					}
				</Timeline>
			</Scene>
		</Controller>
	)
}