import React from "react"
import { graphql} from "gatsby"
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import { BrowserView } from 'react-device-detect';

import { Layout } from "../components/layout"
import Seo from "../components/seo"
import { WorksLink } from "../components/WorksLink"

const worksPage = ({data}) => {

  return (
    <Layout>
      <Seo 
        title="Works"
        description="DESIGN STUDIO KのWorksページです。制作実績を掲載しています。"
      />
      <div className="eyecatch">
        <div className="eyecatch__inner">
          <h1>
            <span>Works</span>
          </h1>
        </div>
        <BrowserView>
          <Controller>
            <Scene
              classToggle={[".eyecatch__under", "enter"]} 
              triggerHook="onEnter"
              triggerElement=".enterContents"
              // offset={100}
            >
              <div className="eyecatch__under">
                <div className={`mv__underRight`} />
              </div>
            </Scene>
          </Controller>
        </BrowserView>
      </div>
      <div className="works__container enterContents">
        <section className="works__containerSec">
          <div className="works__containerSecBlock">
          {data.allMicrocmsWorks.edges.map(({node}, index) => (
          <Controller
            key={index}
          >
            <Scene
              duration="200%"
              triggerHook="onEnter"
            >
              <Timeline>
                <Tween
                  position="0"
                  from={
                    {
                    ...index % 4 === 0 ?
                      {top: '0%'}
                    :
                    index % 4 === 3 ?
                      {top: '0%'}
                    :
                    index % 4 === 2 ?
                      {top: '30%'}
                    :
                      {top: '30%'}
                    }
                  }
                  to={
                    {...index % 4 === 0 ?
                      {top: '0%',}
                    :
                    index % 4 === 3 ?
                      {top: '0%'}
                    :
                    index % 4 === 2 ?
                      {top: '-30%'}
                    :
                      {top: '-30%',}
                    }
                  }
                >
                  <article
                    className="works__containerSecBlockBox"
                  >
                    <WorksLink id={node.worksId} url={node.eyecatch.url} title={node.title} launch={node.launch} />
                  </article>
                </Tween>
              </Timeline>
            </Scene>
          </Controller>
          ))}
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default worksPage

export const query = graphql`
  query {
    allMicrocmsWorks(sort: {fields: publishedAt, order: DESC}) {
      edges {
        node {
          worksId
          title
          launch
          eyecatch {
            url
          }
        }
      }
    }
  }
`